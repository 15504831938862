import _ from 'lodash'
import { FORMS_APP_DEF_ID } from '../constants'
import { siteStore } from './stores/site-store'

export const escapeRegExp = str => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&') //eslint-disable-line no-useless-escape

export const innerText = str => _.trim(str.replace(/\s*<[^>]*>\s*/gm, ''))

export const FIELD_TYPE = {
  RATING: 'RatingsInput',
  CHECKBOX: 'Checkbox',
  CHECKBOX_GROUP: 'CheckboxGroup',
  DATE: 'DatePicker',
  TIME: 'TimePicker',
  CAPTCHA: 'Captcha',
  FILE_UPLOAD: 'UploadButton',
  SIGNATURE: 'SignatureInput',
  RADIO_GROUP: 'RadioButtonGroup',
  TEXT_INPUT: 'TextInput',
}

export const findPlugin = (plugins, pluginId) => _.find(plugins, plugin => plugin.id === pluginId)

export const getFieldType = field => {
  return field.type.split('.')[1]
}

export const replaceMessageInnerText = ($message, replaceFunc: (string) => string): void => {
  const messageText = _.get($message, 'html')
  $message.html = replaceFunc(messageText)
}

const isFieldType = (fieldType: string) => field => field.type === `$w.${fieldType}`

export const isSignatureField = isFieldType(FIELD_TYPE.SIGNATURE)
export const isUploadButton = isFieldType(FIELD_TYPE.FILE_UPLOAD)
export const isCaptchaField = isFieldType(FIELD_TYPE.CAPTCHA)
export const isRadioGroup = isFieldType(FIELD_TYPE.RADIO_GROUP)
export const isCheckbox = isFieldType(FIELD_TYPE.CHECKBOX)
export const isNumber = field =>
  isFieldType(FIELD_TYPE.TEXT_INPUT)(field) && field.inputType === 'number'
export const isDatePicker = isFieldType(FIELD_TYPE.DATE)
export const isTimePicker = isFieldType(FIELD_TYPE.TIME)

export const toMiliseconds = sec => sec * 1000

const PREVIEW_MODE = 'Preview'

export const isTemplate = wixLocation => !wixLocation.baseUrl
export const isPreviewMode = wixWindow => wixWindow.viewMode === PREVIEW_MODE
export const shouldSendData = wixLocation => !isTemplate(wixLocation)

export const getBaseUrl = () => {
  const urlDirs = siteStore.wixApi.location.baseUrl.split('/')
  let baseUrl = urlDirs.slice(0, urlDirs.length - 1).join('/')

  if (baseUrl === 'https:/' || baseUrl === 'http:/') {
    baseUrl = siteStore.wixApi.location.baseUrl
  }

  return baseUrl
}

export const getInstance = wixSite => wixSite.getAppToken(FORMS_APP_DEF_ID)

export const getFieldValueByCrmType = (fields, crmType) =>
  _.get(findFieldByCrmType(fields, crmType), 'value')

export const findFieldByCrmType = (fields: any[], crmType): any =>
  _.find(fields, field => _.get(field, 'connectionConfig.crmType') === crmType)

export const findFieldByPresetType = (fields: any[], role: FieldPreset): any =>
  _.find(fields, field => _.get(field, 'connectionConfig.fieldType') === role)

export const getFieldValidity = fields => {
  const valueMissing = 'valueMissing'
  const errorOrder = [
    valueMissing,
    'fileNotUploaded',
    'typeMismatch',
    'patternMismatch',
    'rangeOverflow',
    'rangeUnderflow',
    'stepMismatch',
    'tooLong',
    'tooShort',
    'badInput',
    'customError',
  ]
  let errorType = _.find(errorOrder, error => _.some(fields, `validity.${error}`))
  const field = _.find(fields, field => {
    if (isCaptchaField(field)) {
      const missingToken = _.isEmpty(field.token)

      if (missingToken) {
        errorType = valueMissing
      }

      return missingToken
    }

    return _.get(field, `validity.${errorType}`)
  })

  return errorType ? `${errorType} : ${_.get(field, 'connectionConfig.crmLabel')}` : ''
}
