import { FormStrategy } from './form-strategy'
import { RegistrationFormStrategy } from './registration-form-strategy'

interface FormStrategyConstructor {
  new ($w): FormStrategy

  isEnabled($w): boolean
  registerCustomValidation?(submitArgs): void
}

export const strategies: FormStrategyConstructor[] = [RegistrationFormStrategy, FormStrategy]
